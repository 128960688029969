<template>
  <li
    :class="[
      'Event',
      last && 'Event--last',
      first && 'Event--first',
      iconClass === '' && 'Event--noIcon',
      completed && 'Event--completed',
    ]"
  >
    <div class="Event__icon">
      <div class="Event__iconInner">
        <i v-if="iconClass" :class="iconClass"></i>
      </div>
    </div>

    <div class="Event__body">
      <div class="Event__title">
        <slot name="title">{{ title }}</slot>
      </div>
      <div class="Event__date">
        <Time v-if="showFullDate" :datetime="date" format="LLL" />
        <TimeFrom v-else :datetime-from="date" />
        <span v-if="location" class="Event__location"
          ><slot name="location">{{ location }}</slot></span
        >
      </div>
      <div v-if="hasContentSlot" class="Event__content">
        <slot name="content" />
      </div>
    </div>
  </li>
</template>

<script>
import TimeFrom from '@components/TimeFrom';
import Time from '@components/Time';
import { eventTypes } from '@types/Event';
export default {
  name: 'Event',

  components: {
    TimeFrom,
    Time,
  },

  props: {
    location: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    last: {
      type: Boolean,
      default: false,
    },
    first: {
      type: Boolean,
      default: false,
    },
    showFullDate: {
      required: false,
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconClass() {
      if (this.icon) {
        return this.icon;
      }
      let iconClass = '';
      switch (this.type) {
        /*case eventTypes.INITIAL:
          iconClass = 'far fa-edit';
          break;*/
        case eventTypes.FINAL:
          iconClass = 'far fa-check';
          break;
        case 'delivery':
          iconClass = 'fas fa-truck';
          break;
        default:
          iconClass = this.first ? 'fas fa-check' : 'far fa-check';
          break;
      }

      return iconClass;
    },
    hasContentSlot() {
      return !!this.$slots['content'] || !!this.$scopedSlots['content'];
    },
  },
};
</script>

<style lang="scss" scoped>
.Event {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 370px;

  .Event_iconInner {
    display: flex;
    justify-content: center;
  }

  .Event__icon {
    width: 34px;
    margin-right: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-prim-brand-vau;

    position: relative;
    z-index: 20;
  }

  &--first .Event__icon {
    border-width: 3px;
    font-size: 14px;
  }

  &--completed .Event__icon {
    color: #bbbbbb;
  }

  .Event__iconInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border: 2px solid $color-prim-brand-vau;
    background: #ffffff;
    border-radius: 100%;
  }

  &--completed .Event__iconInner {
    border: 2px solid #bbbbbb;
  }

  &--first .Event__iconInner {
    width: 34px;
    height: 34px;
    border-width: 3px;
  }

  &.Event--noIcon .Event__icon {
    background: #ffffff !important;
  }

  .Event__title {
    font-weight: bold;
    font-size: 14px;
  }

  &--first .Event__title {
    border-width: 3px;
    font-size: 15px;
  }

  &--completed .Event__title {
    color: #bbbbbb;
  }

  .Event__date {
    color: $color-prim-grey-dark;
    font-size: 12px;
    margin-bottom: 0.4em;
  }

  &--completed .Event__date {
    color: #bbbbbb;
  }

  .Event__content {
    font-size: 12px;
  }

  .Event__body {
    flex: 1;
    max-width: 350px;
    padding-bottom: 20px;
  }

  &:not(.Event--last) .Event__icon:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 3px;
    height: 1000px;
    background: $color-prim-brand-vau;
    z-index: 10;
  }

  &--completed#{&}:not(.Event--last) .Event__icon:before {
    background: #bbbbbb;
  }
}

@mixin printStyles() {
  .Event__icon {
    //border: 1px solid $color-prim-grey;
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
