<template>
  <ul class="Events">
    <template v-if="totalCollapsibleEvents >= 2">
      <Event
        v-for="(event, index) in eventsBefore"
        :key="event.id"
        :title="event.text"
        :type="event.type || ''"
        :date="event.date"
        :icon="event.icon"
        :first="index === 0"
        :show-full-date="showFullDate"
        :completed="index !== 0"
      >
        <template #content>
          <slot name="eventContent" :event="event" :completed="index !== 0" />
        </template>
      </Event>
      <CollapseTimeline :items="totalCollapsibleEvents" @collapse="show = !show" completed />
      <ListTransition>
        <Event
          v-for="(event, index) in eventsAfter"
          :key="event.id"
          :title="event.text"
          :type="event.type || ''"
          :date="event.date"
          :icon="event.icon"
          :last="index === eventsAfter.length - 1"
          :show-full-date="showFullDate"
          completed
        >
          <template #content>
            <slot name="eventContent" :event="event" completed />
          </template>
        </Event>
      </ListTransition>
    </template>
    <template v-else>
      <Event
        v-for="(event, index) in events"
        :key="event.id"
        :title="event.text"
        :type="event.type || ''"
        :date="event.date"
        :icon="event.icon"
        :last="index === events.length - 1"
        :first="index === 0"
        :show-full-date="showFullDate"
        :completed="index !== 0"
      >
        <template #content>
          <slot name="eventContent" :event="event" :completed="index !== 0" />
        </template>
      </Event>
    </template>
  </ul>
</template>

<script>
import Event from './components/Event';
import CollapseTimeline from './components/CollapseTimeline';
import { ListTransition } from '@transitions';

export default {
  name: 'Timeline',

  components: {
    Event,
    CollapseTimeline,
    ListTransition,
  },

  props: {
    events: {
      type: Array,
      required: true,
    },
    showFullDate: {
      type: Boolean,
      default: false,
    },
    showBefore: {
      type: Number,
      default: 2,
    },
    showAfter: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    eventsBefore() {
      return this.events.slice(0, this.showBefore);
    },
    eventsAfter() {
      if (this.show) return this.events.slice(this.showBefore);
      else return this.events.slice(this.events.length - this.showAfter);
    },
    totalCollapsibleEvents() {
      return this.events.length - this.showBefore - this.showAfter;
    },
  },
};
</script>
