<template>
  <li
    :class="[
      'CollapseTimeline',
      show && 'CollapseTimeline--expanded',
      completed && 'CollapseTimeline--completed',
    ]"
  >
    <div class="CollapseTimeline__container">
      <div class="CollapseTimeline__line" />
      <button :aria-label="$t('expand')" class="CollapseTimeline__button" @click="collapse">
        <span v-if="show">{{ $t('order.delivery.shows_all_points') }}</span>
        <span v-else>{{ $t('order.delivery.hides_n_points', { n: items }) }}</span>
        <span class="CollapseTimeline__expand">
          {{ show ? $t('hide') : $t('show') }}
          <i class="CollapseTimeline__icon fas fa-sort-down" />
        </span>
      </button>
    </div>
    <div class="CollapseTimeline__lineUnder" />
  </li>
</template>

<script>
import { Button } from '@components/Buttons';

export default {
  name: 'CollapseTimeline',

  components: {
    Button,
  },

  props: {
    items: {
      type: Number,
      require: true,
    },

    completed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
    collapse() {
      this.$emit('collapse');
      return (this.show = !this.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.CollapseTimeline {
  display: flex;
  flex-direction: column;
  color: $color-prim-grey-dark;
  height: 80px;
  width: 370px;
  user-select: none;

  .CollapseTimeline__container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .CollapseTimeline__line {
    position: relative;
    width: 34px;
    margin-right: 10px;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 3px;
      height: 100%;
      z-index: 10;
      border-left: 3px dashed $color-prim-brand-vau;
    }
  }

  &--completed .CollapseTimeline__line::before {
    border-left: 3px dashed #bbbbbb;
  }

  &--expanded .CollapseTimeline__line::before {
    background: $color-prim-brand-vau;
    border-left: none;
  }

  &--completed#{&}--expanded .CollapseTimeline__line::before {
    background: #bbbbbb;
  }

  .CollapseTimeline__button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    border: none;
    background: none;
  }

  .CollapseTimeline__expand {
    display: flex;
    gap: 3px;
    font-weight: bold;
    color: lighten($color-prim-blue, 10%);
  }

  .CollapseTimeline__icon {
    transition: transform 0.3s linear;
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  &--expanded .CollapseTimeline__icon {
    transform: rotate(-180deg);
  }

  .CollapseTimeline__lineUnder {
    position: relative;
    width: 34px;
    margin-right: 10px;
    height: 30px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 3px;
      height: 100%;
      z-index: 10;
      background: $color-prim-brand-vau;
    }
  }

  &--completed .CollapseTimeline__lineUnder::before {
    background: #bbbbbb;
  }
}
</style>
